import React, {useState, useEffect} from "react";
import "css/modal.css";
import { useCookies } from 'react-cookie';
import { Button, Form } from 'react-bootstrap';
import { setUserData, getUserData } from "api/userAPI";
import { ConstructionOutlined } from "@mui/icons-material";
import {getUserParticipatedContract} from 'api/userAPI';

const MyInfoModal = (props) => {
  const { open, close } = props;
  const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);

  const [inputs, setInputs] = useState({
    mail: "",
    password: "",
    passwordcheck: "",
    name: "",
    birthdate: "",
    phone: "",
  });

  const { mail, password, passwordcheck, name, birthdate, phone } = inputs;
  const onChange = (e) => {
    const { name, value } = e.target;
 
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  useEffect(() => {
    if(!open)
      return;
    console.dir(cookie.userCredential)
    getUserData(cookie.userCredential, (data)=>{
      console.dir(data)
      setInputs({
        ...inputs,
        "name": data.name,
        "birthdate": data.birth.split("T")[0],
        "phone": data.phone,
        "mail": cookie.userCredential.user.email,
      });
    })
  }, [open])
  
  
  return (
    <div className={open ? "openModal modal" : "modal"}>
      <div
        className="onj-modal-backdrop"
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      ></div>
      {open ? (
        <section className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
          <main>
            
          <Form style = {{padding: "30px"}}>
            <Form.Group className="mb-2">
              <Form.Label>이메일 주소 </Form.Label> <br/>
              <Form.Label>{mail} </Form.Label>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>성명</Form.Label>
              <Form.Control name="name" type="text" placeholder="" onChange = {onChange} value = {name}/>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>생년 월일</Form.Label>
              <Form.Control name="birthdate" type="date" placeholder="" onChange = {onChange} value = {birthdate}/>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>핸드폰 번호</Form.Label>
              <Form.Control name="phone" type="text" placeholder="" onChange = {onChange} value = {phone}/>
            </Form.Group>
            <Button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick = {()=> setUserData(cookie.userCredential, name, birthdate, phone, (result)=>{alert(result)})} style = {{width: "100%"}}>
              정보 수정 
            </Button> <br/><br/>
            
      </Form>
          </main>
        </section>
      ) : null}
    </div>
  );
};

export default MyInfoModal;
