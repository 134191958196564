import React, { useState, useRef, useEffect } from 'react'
import Webcam from "react-webcam";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import "css/Agreement.css"
import emailjs from 'emailjs-com';
import { Web } from '@mui/icons-material';
import { Button } from 'ui/Button'
import { SpeakerIcon, SpeakerIconX  } from 'icons/icons';
import { useHistory  } from "react-router-dom";
import { createNewContract } from "api/contractAPI";
import { useCookies } from 'react-cookie';

const useAudio = url => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
		},
		[playing, audio]
	);

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		return () => {
		audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);

	return [playing, toggle];
};

export default function EnterContract() {
	const [playing, toggle] = useAudio('https://media.geeksforgeeks.org/wp-content/uploads/20190625153922/frog.mp3');
	const [agreement1, setAgreement1] = useState(false);
	const [agreement2, setAgreement2] = useState(false);
	const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);
	const [contractBtn, setContractBtn] = useState(<></>);

	useEffect(() => {
	  setAgreement1(false);
	  setAgreement2(false)
	}, [])
	

	function onClickEnter(){
		if(!agreement1 || !agreement2){
			alert("약관에 동의해주세요.")
			return;
		}

		createNewContract(cookie.userCredential, (res)=>{
				let myToken = res.myToken;
				let guestToken = res.guestToken;
				let roomName = res.roomName;
				let baseUrl = "https://contract.vid-sign.com";
				let url = baseUrl + "/?token=" + myToken + "&roomName=" + roomName;
				let guestUrl = baseUrl + "/?token=" + guestToken + "&roomName=" + roomName;
				console.dir(url)
				console.dir(guestUrl)
				setContractBtn(<a href = {url}>
					<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 w-full">
					내 계약으로 이동하기
					</button>
				</a>)
				//window.location.replace(url);
			}
		)
	}
  
	return (
	  <div className="fixed inset-0 bg-gray-50 flex flex-col items-center justify-center">
		<div className='flex flex-row items-center p-10'>
			<div className='flex flex-col w-50 h-full'><Webcam audio width={"100%"} height = {"100%"}/></div>
			<div className='flex flex-col justify-center w-50 p-10'>
				<div class="flex items-center mb-4">
					<input id="default-checkbox" type="checkbox" value={false} onChange = {(e)=>setAgreement1(e.target.checked)} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
					<label for="default-checkbox" class="ml-2 text-sm font-medium text-black-900 font-bold">화상 영상, 화상 대화, 활동 내역, 공유문서 모두 안전하게 녹화되고 저장하는데 동의합니다. </label>
				</div>
				<div class="flex items-center mb-4">
					<input id="default-checkbox2" type="checkbox" value={false} onChange = {(e)=>setAgreement2(e.target.checked)} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/> 
					<label for="default-checkbox2" class="ml-2 text-sm font-medium text-black-900 font-bold"> 말로 협의하여 결정한 내용도, 법적 효력이 있음에 동의합니다. </label>
				</div>
				<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2" onClick={toggle}>
					<div className="w-full content-center" style = {{textAlign: "-webkit-center"}}> {playing?<SpeakerIconX/>:<SpeakerIcon/>}</div>
				</button>
				<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2" onClick={onClickEnter}>
					계약 링크 생성하기
				</button>
				{contractBtn}
			</div>
		</div>
	  </div>
	);
}
