export const signIn = (userCredential, callback) => {
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Authorization", "Bearer " + userCredential.user.accessToken);
  
	fetch(process.env.REACT_APP_BASE_URL_API + "/api/signin/me", {
	  method: "POST",
	  headers: myHeaders,
	  redirect: "manual",
	})
	  .then((response) => response.text())
	  .then((result) => callback())
	  .catch((error) => console.log("error", error));
  };

  export const setUserData = (userCredential, _name, _birth, _phone, callback) => {
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Bearer " + userCredential.user.stsTokenManager.accessToken);

	fetch(process.env.REACT_APP_BASE_URL_API + "/api/user/data", {
	  method: "POST",
	  headers: myHeaders,
	  body: JSON.stringify({name: _name, birth: _birth, phone: _phone}),
	  redirect: "manual",
	})
	  .then((response) => response.text())
	  .then((result) => callback(result))
	  .catch((error) => console.log("error", error));
  };
  
  export const getUserData = (
	userCredential,
	callback
  ) => {
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Authorization", "Bearer " +  userCredential.user.stsTokenManager.accessToken);
  
	fetch(
	  process.env.REACT_APP_BASE_URL_API +
		"/api/user/data",
	  {
		method: "GET",
		headers: myHeaders,
		redirect: "manual",
	  }
	)
	  .then((response) => response.json())
	  .then((result) => callback(result))
	  .catch((error) => console.log("error", error));
  };
  
  export const getUserParticipatedContract = (
	userCredential,
	callback
  ) => {
	console.dir(userCredential)
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Authorization", "Bearer " +  userCredential.user.stsTokenManager.accessToken);
  
	fetch(
	  process.env.REACT_APP_BASE_URL_API +
		"/api/contract/data",
	  {
		method: "GET",
		headers: myHeaders,
		redirect: "manual",
	  }
	)
	  .then((response) => response.json())
	  .then((result) => callback(result))
	  .catch((error) => console.log("error", error));
  };

  export const getContractInfo = (
	userCredential,
	contractId,
	callback
  ) => {
	console.dir(userCredential)
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Authorization", "Bearer " +  userCredential.user.stsTokenManager.accessToken);
  
	fetch(
	  process.env.REACT_APP_BASE_URL_API + 
		"/api/contract/data/info?contractId=" + contractId,
	  {
		method: "GET",
		headers: myHeaders,
		redirect: "manual",
	  }
	)
	  .then((response) => response.json())
	  .then((result) => callback(result))
	  .catch((error) => console.log("error", error));
  };

  export const getAgoraRTCToken = (
	userCredential,
	callback
  ) => {
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Authorization", "Bearer " +  userCredential.user.stsTokenManager.accessToken);
  
	fetch(
	  process.env.REACT_APP_BASE_URL_API + 
		"/api/contract/token",
	  {
		method: "GET",
		headers: myHeaders,
		redirect: "manual",
	  }
	)
	  .then((response) => response.json())
	  .then((result) => callback(result))
	  .catch((error) => console.log("error", error));
  };