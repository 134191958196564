/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useState} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

import LoginModal from "modals/LoginModal";
import CheckContractModal from "modals/CheckContractModal";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

import  Header  from "components/Header";

function AboutUs() {
  const [openLogin, setOpenLogin] = useState(false)
  const [openContractCheck, setOpenContractCheck] = useState(false)
  const [cookie, setCookie] = useCookies(['userCredential']);

  const navigate = useNavigate();

  function onClickStart(){
    if(cookie.userCredential)
      navigate("/mypage");
    else
      setOpenLogin(true)
  }

  return (
    <>
      <LoginModal open = {openLogin} close = {()=>setOpenLogin(false)}/>
      <CheckContractModal open = {openContractCheck} close = {()=>setOpenContractCheck(false)}/>
      <Header onClick={()=>setOpenLogin(true)}/>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              국내 최초 화상 계약 시스템 도입!
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              무슨 문제가 있을 때, CCTV가 정말 중요하죠?
              이젠, 계약 할 때에도,
              전 과정을 화상으로 녹화하여, 이중으로 안전하게 계약을 진행하세요.
            </MKTypography>
            <div style = {{display: "flex", flexDirection: "row"}}>
              <MKButton color="success" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick = {()=>onClickStart()} style = {{marginRight: "10px"}}>
                화상계약 시작하기
              </MKButton>
              <MKButton color="success" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick = {()=>setOpenContractCheck(true)}>
                화상계약 확인하기
              </MKButton>
            </div>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
