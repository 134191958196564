// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signOut,
} from "firebase/auth";

function log(str){
  console.log(str)
}
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

export const registerNewUser = (
  email,
  password,
  callback
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.dir('User creation success')
      console.dir(userCredential)
      // Signed in
      // ...
      log("sign in!");
      callback(true, userCredential);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      log("sign in fail!");
      log(errorCode);
      log(errorMessage);
      callback(false, errorCode);
      // ..
    });
};

export const emailLogin = (
  email,
  password,
  callback
) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      log("logged in!");
      callback(true, userCredential);
      
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      log("log in fail!");
      log(errorCode);
      log(errorMessage);
      callback(false, errorCode);
    });
};

export const emailAlreadyInUse = (
  email,
  callback
) => {
  fetchSignInMethodsForEmail(auth, email)
    .then((data) => {
      if(data && data.length != 0)
        callback(true)
      else
        callback(false)
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      log("fail!");
      log(errorCode);
      log(errorMessage);
      callback(false, errorCode);
    });
};


export const logOut = () => {
  signOut(auth)
    .then((userCredential) => {
      // Logged out
      log("logged out!");
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      log("log in fail!");
      log(errorCode);
      log(errorMessage);
    });
};
