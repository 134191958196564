import React, {useState, useEffect} from "react";
import "css/modal.css";
import {Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserParticipatedContract, getContractInfo} from 'api/userAPI';
import { useCookies } from 'react-cookie';
import { Button } from 'ui/Button'

const MyContractsModal = (props) => {
  const { open, close } = props;
  const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);
  const [content, setContent] = useState();
  const [myContracts, setMyContracts] = useState([]);
  const [contractRows, setContractRows] = useState([]);
  const [contractVid, setContractVid] = useState('');
  
  useEffect(() => {
	console.log('!!')
	getUserParticipatedContract(cookie.userCredential, 
		(data)=>{
			setMyContracts(data.result)
			data.result.map((contract, index)=>{
				getContractInfo(cookie.userCredential, contract.contract, (data)=>{
					console.dir(data)
					contractRows.push(<tr key={index}>
						<td>{index}</td>
						<td>{data.createdAt?.split('T')[0]}</td>
						<td>{""}</td>
						<td>{data.type}</td>
						<td>{""}</td>
						<td><Button title="메모" onClick={()=>setContractVid(<video src = {data.videoUrl} autoplay="autoplay"/>)}/></td>
						<td><Button title="계약 보기" onClick={()=>setContractVid(<video src = {data.videoUrl} autoplay="autoplay"/>)}/></td>
					</tr>)
					setContractRows(
						contractRows.map((row, index)=>{return row})
					)
				})
			})
		});
  }, [])

  function createContractRows(){
	  if(myContracts){
		  return myContracts.map((contract, index) => {
			  return (
				  <tr key={index}>
					  <td>{index}</td>
					  <td>{contract.createdAt}</td>
					  <td>ME</td>
					  <td>{contract.type}</td>
					  <td></td>
					  <td></td>
				  </tr>
			  )
		  })
	  }
  }
  
  return (
    <div className={open ? "openModal modal" : "modal"}>
      <div
        className="onj-modal-backdrop"
        onClick={() => {
          close();
        }}
      ></div>
      {open ? (
        <section className="login-modal-section">
          <main className = "flex flex-col">
		  <div className="p-4 w-full">
			<div>
				<div>나의 계약</div>
				<div>총 {myContracts.length}개의 계약</div>
				<Form>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label><FontAwesomeIcon icon={faSearch} size="2x" /></Form.Label>
						<Form.Control name = "text" type="text" placeholder="" onChange = {{}} />
					</Form.Group>
				</Form>
			</div>
			<table className="table-fixed">
				<thead>
					<tr>
						<th >순번</th>
						<th>계약 일자</th>
						<th>계약 당사자</th>
						<th>계약 내용</th>
						<th>부가 기능</th>
						<th>내 메모</th>
						<th>서류 보기</th>
					</tr>
				</thead>
				{contractRows}
			</table>
			{content}
			</div>
          </main>
        </section>
      ) : null}
    </div>
  );
};

export default MyContractsModal;
