export const createNewContract = (userCredential, callback) => {
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", "Bearer " + userCredential.user.stsTokenManager.accessToken);

	fetch(process.env.REACT_APP_BASE_URL_API + "/api/contract/create", {
	  method: "GET",
	  headers: myHeaders,
	  redirect: "manual",
	})
	  .then((response) => response.text())
	  .then((result) => callback(JSON.parse(result)))
	  .catch((error) => console.log("error", error));
  };