import LoginModal from "modals/LoginModal";
import React, {useState} from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import OnjeonLogo from 'assets/images/logos/onjeon.png'

const Header = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);
  const navigate = useNavigate();

  function onClickLogout(){
	removeCookie('userCredential')
	navigate('/')
  }

  return (
	<div className="relative bg-white">
		<div className="max-w-7xl mx-auto px-4 sm:px-6">
			<div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
				<div className="lg:w-0 lg:flex-1">
					<a href="#" className="flex">
						<img className="h-8 w-auto sm:h-10" src={OnjeonLogo} alt="Workflow"/>
					</a>
				</div>
				<nav className="hidden space-x-10 md:flex">
					<div className="relative">
					<button type="button" className="text-gray-500 group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" aria-expanded="false">
						<span>화상 계약 소개</span>
					</button>
					</div>
				</nav>
				<div className="space-y-6 py-6 px-5">
				{!cookie.userCredential?
					<button onClick={()=>props.onClick()} className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">로그인</button>
				: <button onClick={()=>onClickLogout()} className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">로그아웃</button>
				}	
				</div>
			</div>
		</div>
	</div>
  );
}

export default Header;