import React, {useState, useEffect} from 'react'
import { Button } from 'ui/Button'
import "css/mypage.css";
import MyInfoModal from 'modals/MyInfoModal';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import MyContractsModal from 'modals/MyContractsModal';
import CheckContractModal from 'modals/CheckContractModal';
import {getUserData} from 'api/userAPI';

const MyPageMain = (props) => {
	const [openMyInfo, setOpenMyInfo] = useState(false)
	const [openMyContracts, setOpenMyContracts] = useState(false)
	const [openContractCheck, setOpenContractCheck] = useState(false)
	const [myName, setMyName] = useState('')

	const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);

	const navigate = useNavigate();

	function onClickLogout(){
		removeCookie('userCredential')
		navigate('/')
	}

	useEffect(() => {
		getUserData(cookie.userCredential, (data)=>{
			setMyName(data.name)
		})
	}, [])
	

	return (
	  <div className="" style={{height: "100vh"}}>
		<MyContractsModal open = {openMyContracts} close = {()=>setOpenMyContracts(false)}/>
		<MyInfoModal open = {openMyInfo} close = {()=>setOpenMyInfo(false)}/>
		<CheckContractModal open = {openContractCheck} close = {()=>setOpenContractCheck(false)}/>
		<div className="fixed top-0 right-0 p-20">
			<div className="right-0">{myName} 님! 반갑습니다</div>
			<div className="grid grid-cols-2">
				<div className="col-span-1 p-1">
					<Button title = "내 정보" onClick={()=>setOpenMyInfo(true)}></Button>
				</div>	
				<div className="col-span-1 p-1">
				<Button title = "로그아웃" onClick={()=>onClickLogout(true)}></Button>
				</div>	
			</div>
		</div>
		<div className="grid grid-cols-3 mt-40 p-20 h-2/3">
			<div className="col-span-1 p-5">
				<button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded h-full w-full" onClick={()=>navigate('/contract')}>새로운 계약 진행하기</button>
			</div>
			<div className="col-span-1 p-5">
				<button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded h-full w-full" onClick={()=>setOpenMyContracts(true)}>내 계약 보기</button>
			</div>
			<div className="col-span-1 p-5">
				<button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded h-full w-full" onClick={()=>setOpenContractCheck(true)}>계약 확인하기</button>
			</div>
		</div>
	  </div>
	);
  };
  
  export default MyPageMain;
  