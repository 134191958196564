import React, {useState, useEffect} from "react";
import "css/modal.css";
import { Form } from 'react-bootstrap';
import {Button} from "ui/Button"
import {useCookies} from 'react-cookie';
import {getContractInfo} from 'api/userAPI';

const CheckContractModal = (props) => {
  const { open, close } = props;
  const [contractCode, setContractCode] = useState("");
  const [cookie, setCookie, removeCookie] = useCookies(['userCredential']);
  
  function checkContract(){
	getContractInfo(cookie.userCredential, contractCode, (data)=>{data.result.length > 0 ? alert("계약이 존재합니다.") : alert("계약이 존재하지 않습니다.")})
  }

  return (
	<div className={open ? "openModal modal" : "modal"}>
	<div
	  className="onj-modal-backdrop"
	  onClick={() => {
		// close modal when outside of modal is clicked
		close();
	  }}
	></div>
	{open ? (
	  <section className="login-modal-section">
		  <div className="inline-block w-1/2 bg-gray-500 text-center p-20">
			<div className="font-bold text-2xl">화상 계약 확인하기</div>
			<div className="font-bold text-xl">화상 계약 정보를 열람합니다.</div> <br/><br/> <br/><br/>
			<div className="font-bold text-xl">고유 번호를 오른쪽 칸에 입력해주세요</div> <br/><br/> <br/><br/>
		  </div>
		<div className="w-1/2 p-20">
			<label className="block mb-2 text-sm font-bold text-black-900 text-center mb-2">화상 계약의 고유 번호를 입력해주세요</label>
			<label className="block mb-2 text-sm font-bold text-black-900 text-center mb-2">고유 번호는 24자리의 숫자로 이루어진 고유한 값입니다.</label>
			<input name = "text" type="text"onChange = {(e)=> {setContractCode(e.target.value)}} class="bg-gray-50 mb-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="AAAAAAAAAAAAAAAAAAAAAAAA" required/>
			<Button className = "w-full" title="확인하기" onClick = {()=>checkContract()}/>
		</div>
	  </section>
	) : null}
  </div>
  );
};

export default CheckContractModal;
