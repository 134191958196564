import {useState, useEffect} from "react";
import "css/modal.css";
import { registerNewUser, emailLogin, emailAlreadyInUse } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { signIn, setUserData } from "api/userAPI";
import { Button } from "ui/Button"
import { TitleInput } from "ui/TitleInput"
import KakaoLogo from 'assets/images/logos/KakaoTalk.png'
import OnjeonLogo from 'assets/images/logos/onjeon.png'

const LoginComponent = (props) =>  {
  const navigate = useNavigate();

  const [cookie, setCookie] = useCookies(['userCredential']);

  const [inputs, setInputs] = useState({
    mail: "",
    password: "",
  });

  const { mail, password } = inputs;
  
  const onChange = (e) => {
    const { name, value } = e.target;
 
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  function onLoginCallback(result, data){
    if(result){
      setCookie('userCredential', JSON.stringify(data))
      signIn(data)
      navigate("/mypage");
    } else{
      if(data === "auth/invalid-email")
        alert('이메일 주소가 정확하지 않습니다.')
      else
        alert('이메일 혹은 비밀번호가 틀립니다.')
    }
  }

  return(
  <section className="bg-gray-50 h-full">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
      <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
          <img className="w-8 h-8 mr-2" src={OnjeonLogo} alt="logo"/>
          OnTrack    
      </div>
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="space-y-4 md:space-y-6">
                  <div>
                      <label for="email" className="block mb-2 text-sm font-medium text-gray-900">이메일</label>
                      <input type="email" name="mail" onChange = {onChange} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="onjeon@onjeon.com" required=""/>
                  </div>
                  <div>
                      <label for="password" className="block mb-2 text-sm font-medium text-gray-900">비밀번호</label>
                      <input type="password" name="password" onChange = {onChange} id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                  </div>
                  <div className="flex items-center justify-between">
                      <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required=""/>
                          </div>
                          <div className="ml-3 text-sm">
                            <label for="remember" className="text-gray-500">로그인 유지하기</label>
                          </div>
                      </div>
                      <a href="#" className="text-sm font-medium text-primary-600 hover:underline">비밀번호가 생각나지 않나요?</a>
                  </div>
                  <button className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={()=>emailLogin(mail, password, onLoginCallback)}>로그인</button>
                  <p className="flex-row text-center text-sm mb-5">
                    <div className="mb-2">다른 방법으로 로그인</div>
                    <div className="flex justify-center w-full">
                      <img className = "cursor-pointer" src = {KakaoLogo} alt = "kakao.png" style = {{width: "50px", height: "50px", borderRadius: "50%"}}></img>
                    </div>
                  </p>
                  <div className="text-sm">OnTrack을 이용하는 것은 본 서비스의 <a href = "https://www.law.go.kr/" style={{color:"blue"}}>개인정보 처리 방침</a> 및 <a href = "https://www.law.go.kr/" style={{color:"blue"}}>이용 약관</a> 에 동의한다는 것을 의미합니다.</div>
                        </div>
                    </div>
                </div>
      </div>
    </section>
  )
}

const RegisterComponent = (props) =>  {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    passwordcheck: "",
    name: "",
    birthdate: "",
    phone: "",
  });
  const { email, password, passwordcheck, name, birthdate, phone } = inputs;
  const onChange = (e) => {
    const { name, value } = e.target;
 
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  function onClickRegister(){
    if(password !== passwordcheck){
      alert('비밀번호와 비밀번호 확인이 일치하지 않습니다')
    }
    console.dir(email)
    registerNewUser(email, password, onRegisterCallback)
  }

  function onRegisterCallback(result, data){
    if(result){
      alert('회원가입 성공! 로그인 페이지로 돌아갑니다.')
      props.setRegister(false)
      console.dir(data)
      signIn(data, () => setUserData(data, name, birthdate, phone, (result)=>{console.log(result)}) )
    } else{
      if(data === "auth/invalid-email")
        alert('이메일 주소가 정확하지 않습니다.')
      else if(data === "auth/weak-password")
        alert('비밀번호는 6자 이상으로 설정해주세요')
      else if(data === "auth/email-already-in-use")
        alert('이미 가입되어있는 이메일입니다.')
      else
        alert('알 수 없는 이유로 회원가입을 실패하였습니다.')
    }
  }
  return (
  <div className="w-full h-full">
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 h-full flex flex-col justify-center">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
          이메일 주소
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="email" type="text" placeholder="onjeon@onjeon.com"/>
        <p className="text-red-500 text-xs italic hidden">이메일 주소가 틀렸습니다.</p>
      </div>
      <div className="mb-4">
        <div className="flex flex-row">
          <div className="w-1/2 p-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
            비밀번호
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="password" type="password" placeholder="********"/>
          </div>
          <div className="w-1/2 p-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
            비밀번호 확인
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="passwordcheck" type="password" placeholder="********"/>
          </div>
        </div>
        <div>
          <p className="text-red-500 text-xs italic hidden">비밀번호가 틀립니다.</p>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex flex-row">
          <div className="w-1/2 p-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
            성명
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="name" type="text" placeholder="김온전"/>
          </div>
          <div className="w-1/2 p-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
            생년월일
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="birthdate" type="date" placeholder=""/>
          </div>
        </div>
        <div>
          <p className="text-red-500 text-xs italic hidden">생년월일을 입력해주세요</p>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
          핸드폰 번호
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="phone" type="text" placeholder="010-0000-0000"/>
        <p className="text-red-500 text-xs italic hidden">핸드폰 번호를 입력해주세요</p>
      </div>
      <div className="flex items-center justify-between">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={()=>onClickRegister()}>
          회원가입
        </button>
      </div>
    </div>
</div>
)
}


const LoginModal = (props) => {
  const { open, close } = props;
  const [ register, setRegister ] = useState(false);

  useEffect(() => {
    if(!open)
      setRegister(false);
  }, [open])
  
  return (
    <div className={open ? "openModal modal" : "modal"}>
      <div
        className="onj-modal-backdrop"
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      ></div>
      {open ? (
        <section className="login-modal-section">
            <div className="inline-block w-50 bg-gray-500 text-center p-20">
              {!register?
                <>
                  <div className="font-bold text-2xl">로그인</div>
                  <div className="font-bold text-lg">환영합니다.</div> <br/><br/> <br/><br/>
                  <div className="font-bold text-xl">온트랙에 처음이세요? <a style={{color: "blue", cursor: "pointer"}} onClick={()=>setRegister(true)}>회원 가입</a></div>
                </>:
                <>
                  <div className="font-bold text-2xl">회원 가입</div>
                  <div className="font-bold text-lg">필요한 정보를 입력해주세요</div> <br/><br/> <br/><br/>
                  <div className="font-bold text-xl">이미 회원이신가요? <a style={{color: "blue", cursor: "pointer"}} onClick={()=>setRegister(false)}>로그인</a></div>
                </>}
              </div>
          <div className="w-1/2"> {register?<RegisterComponent setRegister={setRegister}/>:<LoginComponent setRegister={setRegister}/>}</div>
        </section>
      ) : null}
    </div>
  );
};

export default LoginModal;
